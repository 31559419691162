<template>
 <div>
    <v-row no-gutters class="pa-2">
        <v-col cols="12">
          <v-card v-if="!isOnline" flat class="text-center pa-4">
            
            <p style="margin-top: 120px;">
              <v-btn  x-large fab><v-icon>mdi-wifi-off</v-icon></v-btn>
            </p>
              Please connect to the Internet 
          </v-card>
        
          <v-form  
              v-else
                ref="form"
                v-model="valid"
                lazy-validation
                style="width: 100%; margin: auto;"
              >
            <v-card style="margin-top: 12%; background: none;" class="pa-2" flat>
            
              <v-card-text >
                    
                      <v-text-field
                        v-model="ticket_no"
                        :rules="nameRules"
                        label="Enter ticket No."
                        required
                        outlined
                      ></v-text-field>
                   
                      <v-text-field
                        v-model="ticket_code"
                        :rules="nameRules"
                        label="Enter Code"
                        v-on:keyup.enter="validate()"
                        required
                        outlined
                      ></v-text-field>
                    
              </v-card-text>
              <v-card-actions class="pt-4 pb-5">
                  <v-btn
                        color="primary"
                        block
                        rounded
                        large
                        @click="validate()"
                      >
                        validate ticket
                      </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        
        </v-col>
    </v-row>
    <va-win-ticket :show="winticket" :ticket="ticket" @DialogEvent="wtEvent"/>
</div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        winticket: false,
      to_menu: false,
      loading: false,
      valid: false,
      ticket: {},
        search:'',
        from_date:'',
        ticket_no:"",
        ticket_code:"",
         nameRules: [
        v => !!v || 'Field is required'
      ],
    }),
    created() {
      this.PING()
       //this.CHECK_BALANCE(this.user.id)
        //this.to_date =  this.$moment().format("YYYY-MM-DD")
        //this.get_wallet_transaction()
    },
     mounted(){
       this.setLoggedIn(true)
        if (this.user == null) {
          this.$router.push('/')
        }else {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
        this.setOverlayLoading(false)
        }
    },
    computed:{
      isOnline(){
        return this.$store.state.is_online
      },
      user() {
        if(localStorage.user){
            return JSON.parse(localStorage.user)
        }
        return null
      }
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
      wtEvent(){
          this.winticket = false
      },
       validate () {
           
      if (this.$refs.form.validate()) {
         this.ValidateTicket()
       }
      },
      ValidateTicket(){
        this.transactions=[]
        this.setOverlayLoading(true)
          this.$http.post("claim/validate_ticket", {id:parseInt(this.ticket_no), code: this.ticket_code}).then(response => {
              if(response.data.winning_ticket.amount == 0) {
                  this.VA_ALERT("error", "Not a winning ticket!")
              } else {
                  this.ticket = response.data.winning_ticket
                  this.winticket = true
              }
         this.setOverlayLoading(false)
         }).catch(e => {
           console.log(e.data)
         })
      }
    },
  }
</script>